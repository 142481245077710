import { Routes, Route } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { mainTheme } from "./assets/themes";
import NavBar from "./components/NavBar";
import NavBarMobile from "./components/NavBarMobile";
import Footer from "./components/Footer";
import MainPage from "./components/MainPage";
import AddMatches from "./components/AddMatches";
import OtherGames from "./components/OtherGames";

function App() {
  const desktop = useMediaQuery("(min-width:850px)");

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Box
        display="flex"
        flexDirection="column"
        sx={{ minHeight: "100vh", justifyContent: "space-between" }}
      >
        <div>
          {desktop ? <NavBar /> : <NavBarMobile />}
          <Routes>
            <Route
              path="/"
              element={desktop ? <MainPage /> : <MainPage mobile />}
            />
            <Route path="/othergames" element={<OtherGames />} />
            <Route path="/addmatches" element={<AddMatches />} />
          </Routes>
        </div>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
