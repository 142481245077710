import { Link } from "react-router-dom";
import { Box, AppBar, Typography, Button, Toolbar } from "@mui/material";
import logo from "../assets/logo.png";

export default function NavBar() {
  return (
    <nav>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" elevation={1} enableColorOnDark>
          <Toolbar variant="dense">
            <Link to="/">
              <Box
                component="img"
                src={logo}
                alt="Home"
                sx={{ width: 70, mt: "6px" }}
              />
            </Link>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Typography
                color="textPrimary"
                fontSize={18}
                fontWeight={100}
                sx={{ ml: 1 }}
              >
                Replay Theater
              </Typography>
            </Link>
            <Link to="/othergames" style={{ textDecoration: "none" }}>
              <Button
                variant="text"
                color="inherit"
                sx={{ ml: 2, fontSize: 15, fontWeight: 400, color: "white" }}
              >
                Other Games
              </Button>
            </Link>
            <Link to="/addmatches" style={{ textDecoration: "none" }}>
              <Button
                variant="text"
                color="inherit"
                sx={{ ml: 2, fontSize: 15, fontWeight: 400, color: "white" }}
              >
                Add Matches +
              </Button>
            </Link>
          </Toolbar>
        </AppBar>
      </Box>
    </nav>
  );
}
