import { Paper, Box, Typography } from "@mui/material";

export default function Footer() {
  return (
    <footer>
      <Box display="flex" sx={{ height: 35, flexGrow: 1, textAlign: "center" }}>
        <Paper
          component={Box}
          display="flex"
          square
          elevation={4}
          sx={{ width: "100%", justifyContent: "center" }}
        >
          <a
            href={"mailto:setzerfgdb@gmail.com"}
            target="_blank"
            rel="noreferrer"
            style={{ alignSelf: "center", textDecoration: "none" }}
          >
            <Typography
              color="secondary.main"
              fontSize={14}
              sx={{ alignSelf: "center" }}
            >
              setzerfgdb@gmail.com
            </Typography>
          </a>
        </Paper>
      </Box>
    </footer>
  );
}
