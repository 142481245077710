import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Stack,
  Alert,
  Card,
  Divider,
  Box,
  TextField,
  Typography,
  Tabs,
  Tab,
  Snackbar,
  IconButton,
} from "@mui/material";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import GameSelect from "./GameSelect";
import CharacterSelect from "./CharacterSelect";
import RemoveIcon from "@mui/icons-material/Remove";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export default function AddMatches() {
  const [inputMethod, setInputMethod] = useState(0);
  const [game, setGame] = useState("");
  const [url, setURL] = useState("");
  const [timestamps, setTimestamps] = useState("");
  const example = `Example:\n1:00 Chris (Narukami) vs *CZ (Margaret)\n6:07 WesJTurnes (Rise) vs Kyokugen (Labrys)\n16:24 Milpy (Shadow Labrys) vs Panxut (Adachi)\n21:59 Hero (Aigis) vs Aurha (Labrys)`;
  const [urlError, setURLError] = useState(false);
  const [timestampError, setTimeStampError] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [matchCards, setMatchCards] = useState([]);

  const threeChars = game === "umvc3" || game === "kofxv";
  const twoChars = game === "bbtag" || game === "asbr";
  const regex =
    /^(?:(\d+):)?(\d+):(\d{2}) (.+) \((?:([^/]+)|([^/]+)\/([^/]+)|([^/]+)\/([^/]+)\/([^/]+))\) vs\.? (.+) \((?:([^/]+)|([^/]+)\/([^/]+)|([^/]+)\/([^/]+)\/([^/]+))\)$/im;

  const verify = async () => {
    let error = false;
    if (
      !url.includes("youtube.com") &&
      !url.includes("nicovideo.jp") &&
      !url.includes("youtu.be") &&
      !url.includes("nico.ms") &&
      !url.includes("twitch.tv")
    ) {
      setURLError(true);
      error = true;
    }

    if (!game) {
      error = true;
    }

    if (inputMethod === 1) {
      let split = timestamps
        .split("\n")
        .map((m) => m.trim())
        .filter((l) => l !== "");
      let matches = split.map((m) => m.match(regex)).filter((m) => m);

      if (!timestamps || split.length !== matches.length) {
        setTimeStampError(true);
        error = true;
      }

      if (error) {
        setOpenError(true);
        return;
      }

      setLoading(true);
      matches = matches.map((m) => {
        let time =
          (parseInt(m[1]) * 3600 || 0) +
          (parseInt(m[2]) * 60 || 0) +
          parseInt(m[3]);
        return {
          game: game,
          tag: null,
          upload_date: null,
          video_link:
            url + (url.includes("nico") ? `?from=${time}` : `&t=${time}s`),
          p1_name: m[4],
          p2_name: m[11],
          p1_char: m[5] || m[6] || m[8],
          p1_char2: m[7] || m[9] ? m[7] || m[9] : null,
          p1_char3: m[10] ? m[10] : null,
          p2_char: m[12] || m[13] || m[15],
          p2_char2: m[14] || m[16] ? m[14] || m[16] : null,
          p2_char3: m[17] ? m[17] : null,
        };
      });

      try {
        await axios.post("/api/mail", { data: matches });
        setURL("");
        setTimestamps("");
        setMatchCards([{ id: uuidv4() }]);
        setOpenSuccess(true);
        setLoading(false);
      } catch (error) {
        setSubmitError(true);
        setLoading(false);
      }
    } else {
      const matches = matchCards.map((m) => {
        if (!m.timestamp) {
          error = true;
          return m;
        }

        const ts = m.timestamp.trim().match(/^(?:(\d+):)?(\d+):(\d{2})$/);

        if (!ts || !m.p1.trim() || !m.p2.trim() || !m.p1C1 || !m.p2C1) {
          error = true;
        }
        if (threeChars && (!m.p1C2 || !m.p1C3 || !m.p2C2 || !m.p2C3)) {
          error = true;
        }
        if (twoChars && (!m.p1C2 || !m.p2C2)) {
          error = true;
        }
        if (
          threeChars &&
          (m.p1C1 === m.p1C2 ||
            m.p1C1 === m.p1C3 ||
            m.p1C2 === m.p1C3 ||
            m.p2C1 === m.p2C2 ||
            m.p2C1 === m.p2C3 ||
            m.p2C2 === m.p2C3)
        ) {
          error = true;
        }
        if (twoChars && (m.p1C1 === m.p1C2 || m.p2C1 === m.p2C2)) {
          error = true;
        }

        const parsedTimestamp =
          (parseInt(ts[1]) * 3600 || 0) +
          (parseInt(ts[2]) * 60 || 0) +
          parseInt(ts[3]);

        return {
          game: game,
          tag: null,
          upload_date: null,
          video_link:
            url +
            (url.includes("nico")
              ? `?from=${parsedTimestamp}`
              : `&t=${parsedTimestamp}s`),
          p1_name: m.p1.trim(),
          p2_name: m.p2.trim(),
          p1_char: m.p1C1,
          p1_char2: m.p1C2 || null,
          p1_char3: m.p1C3 || null,
          p2_char: m.p2C1,
          p2_char2: m.p2C2 || null,
          p2_char3: m.p2C3 || null,
        };
      });
      if (error) {
        setOpenError(true);
        return;
      }

      setLoading(true);
      try {
        await axios.post("/api/mail", { data: matches });
        setURL("");
        setTimestamps("");
        setMatchCards([{ id: uuidv4() }]);
        setOpenSuccess(true);
        setLoading(false);
      } catch (error) {
        setSubmitError(true);
        setLoading(false);
      }
    }
  };

  return (
    <Box display="flex" sx={{ justifyContent: "center", flexGrow: 1 }}>
      <Stack
        display="flex"
        component={Box}
        spacing={0}
        sx={{
          mt: 4,
          maxWidth: 800,
          flexGrow: 1,
          justifyContent: "center",
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <Box display="flex" justifyContent="center" sx={{ flexGrow: 1, mb: 3 }}>
          <GameSelect
            game={game}
            onGameChange={async (game) => {
              setGame(game);
              setMatchCards([{ id: uuidv4() }]);
            }}
          />
        </Box>
        {game && (
          <>
            <Divider sx={{ mb: 3 }} />
            <Tabs
              value={inputMethod}
              variant="fullWidth"
              onChange={(e, v) => {
                if (!loading) {
                  setInputMethod(v);
                }
              }}
              centered
              sx={{ mb: 3.5 }}
            >
              <Tab label="Form Input" />
              <Tab label="Text Input" />
            </Tabs>
            <TextField
              value={url}
              error={urlError}
              onFocus={() => setURLError(false)}
              onChange={(e) => setURL(e.target.value)}
              label="Base Video URL"
              size="small"
              variant="outlined"
              sx={{ mb: 3 }}
            />
            <div hidden={!(inputMethod === 1)}>
              <Typography fontSize={13} sx={{ mb: 0.4 }}>
                {" "}
                Timestamp Format: timestamp Player 1 (Point/Middle/Anchor) vs
                Player 2 (Point/Middle/Anchor){" "}
              </Typography>
              <Typography fontSize={13} sx={{ mb: 2 }}>
                {" "}
                Example Row: 1:35 RayRay (Magneto/Doom/Sentinel) vs ApologyMan
                (Firebrand/Doom/Skrull){" "}
              </Typography>
              <TextField
                value={timestamps}
                multiline
                error={timestampError}
                spellCheck="false"
                onFocus={() => setTimeStampError(false)}
                placeholder={example}
                onChange={(e) => setTimestamps(e.target.value)}
                label="Video Timestamps"
                variant="outlined"
                fullWidth
                rows={10}
                sx={{ mb: 3 }}
              />
            </div>
            <div hidden={!(inputMethod === 0)}>
              {matchCards.map((m, i) => (
                <Box display="flex" justifyContent="center" key={m.id}>
                  <MatchCard
                    key={game}
                    id={m.id}
                    game={game}
                    threeChars={threeChars}
                    twoChars={twoChars}
                    updateData={(data) =>
                      setMatchCards(
                        matchCards.map((prevDat, n) => {
                          if (i === n) {
                            return data;
                          }
                          return prevDat;
                        })
                      )
                    }
                    onDelete={() => {
                      setMatchCards(matchCards.filter((_, n) => i !== n));
                    }}
                  />
                </Box>
              ))}
              <Box
                display="flex"
                sx={{
                  justifyContent: "center",
                  mt: -1,
                  mb: 3,
                }}
              >
                <IconButton
                  onClick={() =>
                    setMatchCards(matchCards.concat({ id: uuidv4() }))
                  }
                >
                  <AddCircleIcon sx={{ fontSize: "30px" }} />
                </IconButton>
              </Box>
            </div>
            {(inputMethod === 1 || matchCards.length !== 0) && (
              <Box display="flex" flexGrow={1} justifyContent="center">
                <LoadingButton
                  variant="contained"
                  onClick={verify}
                  loading={loading}
                  disabled={loading}
                  sx={{ width: 150, mb: 5 }}
                >
                  Submit
                </LoadingButton>
              </Box>
            )}
          </>
        )}
      </Stack>
      <Snackbar
        open={openError}
        autoHideDuration={3000}
        onClose={() => setOpenError(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Invalid/missing fields
        </Alert>
      </Snackbar>
      <Snackbar
        open={submitError}
        autoHideDuration={7000}
        onClose={() => setSubmitError(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Error submitting match
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={9000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Your matches have been submitted and will be posted once verified 👍
        </Alert>
      </Snackbar>
    </Box>
  );
}

function MatchCard(props) {
  const game = props.game;
  const updateData = props.updateData;
  const id = props.id;
  const threeChars = props.threeChars;
  const twoChars = props.twoChars;
  const [timestamp, setTimestamp] = useState("0:00");
  const [p1, setP1] = useState("");
  const [p2, setP2] = useState("");
  const [p1C1, setP1C1] = useState("");
  const [p1C2, setP1C2] = useState("");
  const [p1C3, setP1C3] = useState("");
  const [p2C1, setP2C1] = useState("");
  const [p2C2, setP2C2] = useState("");
  const [p2C3, setP2C3] = useState("");

  useEffect(() => {
    updateData({
      id: id,
      timestamp: timestamp,
      p1: p1,
      p2: p2,
      p1C1: p1C1,
      p1C2: p1C2,
      p1C3: p1C3,
      p2C1: p2C1,
      p2C2: p2C2,
      p2C3: p2C3,
    });
  }, [timestamp, p1, p2, p1C1, p1C2, p1C3, p2C1, p2C2, p2C3]);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          boxShadow: 1,
          mb: 4,
          backgroundColor: "secondBackground.main",
          alignSelf: "center",
          width: "100%",
          maxWidth: 400,
        }}
      >
        <Stack
          component={Box}
          display="flex"
          spacing={0}
          sx={{
            flexGrow: 1,
            textAlign: "center",
            alignContent: "center",
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Box
            display="flex"
            sx={{
              mt: 2,
              ml: 2,
              mr: 2,
              justifyContent: "left",
            }}
          >
            <TextField
              spellCheck="false"
              value={timestamp}
              onChange={(e) => setTimestamp(e.target.value)}
              label="Timestamp"
              variant="standard"
              size="small"
              sx={{ mb: 3, width: "80px" }}
            />
            <Box width="100%" textAlign="end">
              <IconButton onClick={() => props.onDelete()}>
                <RemoveIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            display="flex"
            sx={{
              justifyContent: "left",
              mt: -1,
              mr: 2,
              ml: "5px",
            }}
          >
            <Box display="flex" alignItems="end">
              {threeChars && (
                <>
                  <Box mr={-2.5}>
                    <CharacterSelect
                      game={game}
                      char={p1C3}
                      onCharChange={(e) => setP1C3(e)}
                      hideAny
                      size="small"
                    />
                  </Box>
                </>
              )}
              {(threeChars || twoChars) && (
                <>
                  <Box mr={-2}>
                    <CharacterSelect
                      game={game}
                      char={p1C2}
                      onCharChange={(e) => setP1C2(e)}
                      hideAny
                      size="medium_small"
                    />
                  </Box>
                </>
              )}
              <Box>
                <CharacterSelect
                  game={game}
                  char={p1C1}
                  hideAny
                  onCharChange={(e) => setP1C1(e)}
                  size="medium"
                />
              </Box>
            </Box>
            <TextField
              size="small"
              spellCheck="false"
              value={p1}
              onChange={(e) => setP1(e.target.value)}
              fullWidth
              placeholder="Player 1"
              sx={{ mt: "6.5px" }}
            />
          </Box>
          <Box
            display="flex"
            sx={{
              justifyContent: "left",
              mr: 2,
              ml: "5px",
              mb: 1,
            }}
          >
            <Box display="flex" alignItems="end">
              {threeChars && (
                <>
                  <Box mr={-2.5}>
                    <CharacterSelect
                      game={game}
                      char={p2C3}
                      onCharChange={(e) => setP2C3(e)}
                      hideAny
                      size="small"
                    />
                  </Box>
                </>
              )}
              {(threeChars || twoChars) && (
                <>
                  <Box mr={-2}>
                    <CharacterSelect
                      game={game}
                      char={p2C2}
                      onCharChange={(e) => setP2C2(e)}
                      hideAny
                      size="medium_small"
                    />
                  </Box>
                </>
              )}
              <Box>
                <CharacterSelect
                  game={game}
                  char={p2C1}
                  hideAny
                  onCharChange={(e) => setP2C1(e)}
                  size="medium"
                />
              </Box>
            </Box>
            <TextField
              size="small"
              spellCheck="false"
              fullWidth
              value={p2}
              onChange={(e) => setP2(e.target.value)}
              placeholder="Player 2"
              sx={{ mt: "6.5px" }}
            />
          </Box>
        </Stack>
      </Card>
    </>
  );
}
