import { createTheme } from '@mui/material/styles';

export const mainTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#794798'
    },
    secondary: {
      main: '#DA4167',
    },
    background: {
      default: '#2D2A2E',
    },
    secondBackground: {
      main: '#403E41'
    },
  },
});

export const testTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#000000',
    },
    background: {
      default: '#303030',
    },
  },
});