import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";
import {
  Pagination,
  LinearProgress,
  Stack,
  Divider,
  Grid,
  Avatar,
  Box,
  Typography,
  Container,
  TextField,
  FormControlLabel,
  Switch,
  Card,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CharacterSelect from "./CharacterSelect";
import GameSelect from "./GameSelect";
import { gameMap } from "../assets/GameMap";

const axios = setupCache(Axios);

export default function MainPage(props) {
  const pageSiblingCount = useMediaQuery("(min-width:400px)") ? 1 : 0;
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [matches, setMatches] = useState([]);
  const paramPage = parseInt(searchParams.get("page")) || 1;
  const [maxPage, setMaxPage] = useState(0);

  const paramGame = searchParams.get("game");
  const paramP1 = searchParams.get("p1");
  const paramP2 = searchParams.get("p2");
  const paramC1 = searchParams.get("c1");
  const paramC2 = searchParams.get("c2");
  const paramP1C2 = searchParams.get("p1c2");
  const paramP1C3 = searchParams.get("p1c3");
  const paramP2C2 = searchParams.get("p2c2");
  const paramP2C3 = searchParams.get("p2c3");
  const paramOrder = searchParams.get("order");
  const paramTag = searchParams.get("tag");

  const changePage = async (event, p) => {
    if (searchParams.get("page") == p) {
      return;
    }
    window.scrollTo(0, 0);
    searchParams.set("page", p);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (!paramGame) {
      setMaxPage(0);
      setMatches([]);
      return;
    } else {
      setLoading(true);
      axios.get("/api/matches" + window.location.search).then(
        (response) => {
          setMatches(response.data.matches);
          setMaxPage(Math.ceil(response.data["total_count"] / 50));
          setLoading(false);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [
    paramGame,
    paramP1,
    paramP2,
    paramC1,
    paramC2,
    paramP1C2,
    paramP1C3,
    paramP2C2,
    paramP2C3,
    paramOrder,
    paramTag,
    paramPage,
  ]);

  return (
    <>
      {!props.mobile ? (
        <div>
          <QueryForm />
          <Container sx={{ mt: 4, mb: -3 }}>
            <Box display="flex" sx={{ justifyContent: "center" }}>
              <Pagination
                count={maxPage}
                hidden={!paramGame}
                siblingCount={2}
                page={paramPage}
                onChange={changePage}
                color="primary"
              />
            </Box>
          </Container>
          {loading ? (
            <Container display="flex" sx={{ mt: 7, justifyItems: "center" }}>
              <LinearProgress />
            </Container>
          ) : (
            <MatchList matches={matches} page={paramPage} maxPage={maxPage} />
          )}
          <Container sx={{ mt: 4, mb: 3 }}>
            <Box display="flex" sx={{ justifyContent: "center" }}>
              <Pagination
                count={maxPage}
                hidden={!paramGame}
                siblingCount={2}
                page={paramPage}
                onChange={changePage}
                color="primary"
              />
            </Box>
          </Container>
        </div>
      ) : (
        <>
          <div>
            <QueryForm mobile />
            <Container sx={{ mt: 1, mb: -3 }}>
              <Box display="flex" sx={{ justifyContent: "center" }}>
                <Pagination
                  count={maxPage}
                  hidden={!paramGame}
                  siblingCount={pageSiblingCount}
                  page={paramPage}
                  onChange={changePage}
                  color="primary"
                />
              </Box>
            </Container>
            {loading ? (
              <Container display="flex" sx={{ mt: 7, justifyItems: "center" }}>
                <LinearProgress />
              </Container>
            ) : (
              <MatchList
                matches={matches}
                page={paramPage}
                maxPage={maxPage}
                mobile
              />
            )}
            <Container sx={{ mt: 3, mb: 3 }}>
              <Box display="flex" sx={{ justifyContent: "center" }}>
                <Pagination
                  count={maxPage}
                  hidden={!paramGame}
                  siblingCount={pageSiblingCount}
                  page={paramPage}
                  onChange={changePage}
                  color="primary"
                />
              </Box>
            </Container>
          </div>
        </>
      )}
    </>
  );
}

function MatchList(props) {
  return (
    <>
      {!props.mobile ? (
        <Container sx={{ mt: 7 }}>
          <Stack divider={<Divider flexItem />} spacing={1.5} sx={{}}>
            {props.matches.map((match) => (
              <a
                href={match.video_link}
                key={match.id}
                style={{ textDecoration: "none", color: "white" }}
              >
                <Grid display="flex" sx={{ justifyContent: "space-between" }}>
                  <Grid item display="flex" xs md lg xl>
                    <Typography
                      variant="caption"
                      sx={{ alignSelf: "center", lineHeight: 1 }}
                    >
                      {match.upload_date}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    display="flex"
                    sx={{ justifyContent: "center" }}
                    xs={8}
                    md={7}
                  >
                    <Grid
                      item
                      display="flex"
                      sx={{ justifyContent: "end", alignItems: "center" }}
                      xs={5}
                    >
                      <Typography
                        sx={{
                          alignSelf: "center",
                          mr: 1.5,
                          textAlign: "end",
                        }}
                      >
                        {match.p1_name}
                      </Typography>
                      {match.p1_char3 && (
                        <Avatar
                          variant="rounded"
                          src={`/character_icons/${
                            gameMap[match.game]
                          }/${match.p1_char3.replace(/[♯?]/, "")}.png`}
                          sx={{
                            height: 24,
                            width: 24,
                            mr: 0.5,
                            imageRendering: "-webkit-optimize-contrast",
                          }}
                        />
                      )}
                      {match.p1_char2 && (
                        <Avatar
                          variant="rounded"
                          src={`/character_icons/${
                            gameMap[match.game]
                          }/${match.p1_char2.replace(/[♯?]/, "")}.png`}
                          sx={{
                            height: 32,
                            width: 32,
                            mr: 0.5,
                            imageRendering: "-webkit-optimize-contrast",
                          }}
                        />
                      )}
                      <Avatar
                        variant="rounded"
                        src={`/character_icons/${
                          gameMap[match.game]
                        }/${match.p1_char.replace(/[♯?]/, "")}.png`}
                        sx={{
                          height: 40,
                          width: 40,
                          imageRendering: "-webkit-optimize-contrast",
                        }}
                      />
                    </Grid>
                    <Grid item display="flex">
                      <Typography
                        align="center"
                        sx={{ alignSelf: "center", mr: 0.7, ml: 0.7 }}
                      >
                        vs
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      sx={{ alignItems: "center" }}
                      xs={5}
                    >
                      <Avatar
                        variant="rounded"
                        src={`/character_icons/${
                          gameMap[match.game]
                        }/${match.p2_char.replace(/[♯?]/, "")}.png`}
                        sx={{
                          height: 40,
                          width: 40,
                          imageRendering: "-webkit-optimize-contrast",
                        }}
                      />
                      {match.p2_char2 && (
                        <Avatar
                          variant="rounded"
                          src={`/character_icons/${
                            gameMap[match.game]
                          }/${match.p2_char2.replace(/[♯?]/, "")}.png`}
                          sx={{
                            height: 32,
                            width: 32,
                            ml: 0.5,
                            imageRendering: "-webkit-optimize-contrast",
                          }}
                        />
                      )}
                      {match.p2_char3 && (
                        <Avatar
                          variant="rounded"
                          src={`/character_icons/${
                            gameMap[match.game]
                          }/${match.p2_char3.replace(/[♯?]/, "")}.png`}
                          sx={{
                            height: 24,
                            width: 24,
                            ml: 0.5,
                            imageRendering: "-webkit-optimize-contrast",
                          }}
                        />
                      )}
                      <Typography sx={{ alignSelf: "center", ml: 1.5 }}>
                        {match.p2_name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    sx={{ justifyContent: "end" }}
                    xs
                    md
                    lg
                    xl
                  >
                    {match.tag && (
                      <Typography
                        variant="caption"
                        sx={{
                          alignSelf: "center",
                          textAlign: "end",
                        }}
                      >
                        {match.tag}
                      </Typography>
                    )}
                    <ArrowForwardIcon
                      fontSize="small"
                      sx={{ alignSelf: "center", ml: 0.3 }}
                    />
                  </Grid>
                </Grid>
              </a>
            ))}
          </Stack>
        </Container>
      ) : (
        <Container sx={{ mt: 7 }}>
          <Stack spacing={1.5} sx={{}}>
            {props.matches.map((match) => (
              <Card
                key={match.id}
                variant="outlined"
                sx={{
                  boxShadow: 1,
                  backgroundColor: "secondBackground.main",
                }}
              >
                <Stack
                  component={Container}
                  display="flex"
                  divider={<Divider />}
                  spacing={0}
                  sx={{
                    flexGrow: 1,
                    textAlign: "center",
                    alignContent: "center",
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  <Box
                    display="flex"
                    flexGrow={1}
                    justifyContent="space-between"
                    sx={{
                      mt: 1,
                      mb: 1,
                      paddingLeft: 1.5,
                      paddingRight: 1.5,
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        alignSelf: "center",
                        display: "flex",
                        fontSize: 13,
                      }}
                    >
                      {match.upload_date}
                    </Typography>
                    <a
                      href={match.video_link}
                      key={match.id}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "white",
                        display: "flex",
                      }}
                    >
                      <ArrowForwardIcon
                        fontSize="small"
                        sx={{ alignSelf: "center" }}
                      />
                    </a>
                  </Box>
                  <Stack
                    display="flex"
                    sx={{ paddingLeft: 1.5, paddingRight: 1.5 }}
                  >
                    <Box
                      display="flex"
                      flexGrow={1}
                      marginBottom={1}
                      marginTop={1}
                    >
                      {match.p1_char3 && (
                        <Avatar
                          variant="rounded"
                          src={`/character_icons/${
                            gameMap[match.game]
                          }/${match.p1_char3.replace(/[♯?]/, "")}.png`}
                          sx={{
                            height: 24,
                            width: 24,
                            mr: 0.5,
                            alignSelf: "center",
                            imageRendering: "-webkit-optimize-contrast",
                          }}
                        />
                      )}
                      {match.p1_char2 && (
                        <Avatar
                          variant="rounded"
                          src={`/character_icons/${
                            gameMap[match.game]
                          }/${match.p1_char2.replace(/[♯?]/, "")}.png`}
                          sx={{
                            height: 32,
                            width: 32,
                            mr: 0.5,
                            alignSelf: "center",
                            imageRendering: "-webkit-optimize-contrast",
                          }}
                        />
                      )}
                      <Avatar
                        variant="rounded"
                        src={`/character_icons/${
                          gameMap[match.game]
                        }/${match.p1_char.replace(/[♯?]/, "")}.png`}
                        sx={{
                          height: 40,
                          width: 40,
                          imageRendering: "-webkit-optimize-contrast",
                        }}
                      />
                      <Typography sx={{ alignSelf: "center", ml: 1 }}>
                        {match.p1_name}
                      </Typography>
                    </Box>
                    <Box display="flex" flexGrow={1} marginBottom={1}>
                      {match.p2_char3 && (
                        <Avatar
                          variant="rounded"
                          src={`/character_icons/${
                            gameMap[match.game]
                          }/${match.p2_char3.replace(/[♯?]/, "")}.png`}
                          sx={{
                            height: 24,
                            width: 24,
                            mr: 0.5,
                            alignSelf: "center",
                            imageRendering: "-webkit-optimize-contrast",
                          }}
                        />
                      )}
                      {match.p2_char2 && (
                        <Avatar
                          variant="rounded"
                          src={`/character_icons/${
                            gameMap[match.game]
                          }/${match.p2_char2.replace(/[♯?]/, "")}.png`}
                          sx={{
                            height: 32,
                            width: 32,
                            mr: 0.5,
                            alignSelf: "center",
                            imageRendering: "-webkit-optimize-contrast",
                          }}
                        />
                      )}
                      <Avatar
                        variant="rounded"
                        src={`/character_icons/${
                          gameMap[match.game]
                        }/${match.p2_char.replace(/[♯?]/, "")}.png`}
                        sx={{
                          height: 40,
                          width: 40,
                          imageRendering: "-webkit-optimize-contrast",
                        }}
                      />
                      <Typography sx={{ alignSelf: "center", ml: 1 }}>
                        {match.p2_name}
                      </Typography>
                    </Box>
                  </Stack>
                  {match.tag && (
                    <Box
                      display="flex"
                      flexGrow={1}
                      sx={{
                        paddingLeft: 1.5,
                        paddingRight: 1.5,
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          mb: 1,
                          mt: 1,
                          alignSelf: "center",
                          display: "flex",
                          fontSize: 13,
                        }}
                      >
                        {match.tag}
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </Card>
            ))}
          </Stack>
        </Container>
      )}
    </>
  );
}

function QueryForm(props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const paramGame = searchParams.get("game") || "";
  const paramP1 = searchParams.get("p1") || "";
  const paramP2 = searchParams.get("p2") || "";
  const paramC1 = searchParams.get("c1") || "";
  const paramC2 = searchParams.get("c2") || "";
  const paramP1C2 = searchParams.get("p1c2") || "";
  const paramP1C3 = searchParams.get("p1c3") || "";
  const paramP2C2 = searchParams.get("p2c2") || "";
  const paramP2C3 = searchParams.get("p2c3") || "";
  const paramOrder = searchParams.get("order") || false;
  const paramTag = searchParams.get("tag") || "";

  const [p1, setP1] = useState(paramP1);
  const [p2, setP2] = useState(paramP2);
  const [tag, setTag] = useState(paramTag);

  const threeChars = paramGame === "umvc3" || paramGame === "kofxv";
  const twoChars = paramGame === "bbtag" || paramGame === "asbr";
  const fixedOrder = paramGame === "asbr";

  useEffect(() => {
    setP1(paramP1);
    setP2(paramP2);
    setTag(paramTag);
  }, [paramP1, paramP2, paramTag]);

  const gameChange = async (game) => {
    if (paramGame !== game) {
      setSearchParams({ game: game });
    }
  };

  const textQuerySet = async (event, param, paramVal) => {
    if (
      event.target.value === paramVal ||
      (event.target.value === "" && !paramVal)
    ) {
      return;
    }
    if (event.target.value === "") {
      searchParams.delete(param);
    } else {
      searchParams.set(param, event.target.value);
    }
    await searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const charChange = async (char, param, paramVal) => {
    if (char === paramVal || (char === "Any" && !paramVal)) {
      return;
    }
    if (char === "Any") {
      await searchParams.delete(param);
    } else {
      await searchParams.set(param, char);
    }
    await searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const orderChange = async (event) => {
    await searchParams.set("order", event.target.checked);
    await searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  return (
    <>
      {!props.mobile ? (
        <Container display="flex">
          <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
            <GameSelect game={paramGame} onGameChange={gameChange} />
          </Box>
          {paramGame && (
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="end"
              sx={{ mt: 3 }}
            >
              {threeChars && (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{ mr: 0, width: 50 }}
                  >
                    <CharacterSelect
                      game={paramGame}
                      char={paramP1C3}
                      onCharChange={(e) => charChange(e, "p1c3", paramP1C3)}
                      size="small"
                    />
                  </Box>
                </>
              )}
              {(threeChars || twoChars) && (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{ mr: 0, width: 50 }}
                  >
                    <CharacterSelect
                      game={paramGame}
                      char={paramP1C2}
                      onCharChange={(e) => charChange(e, "p1c2", paramP1C2)}
                      size="medium"
                    />
                  </Box>
                </>
              )}
              <CharacterSelect
                game={paramGame}
                char={paramC1}
                onCharChange={(e) => charChange(e, "c1", paramC1)}
                size="large"
              />
              <TextField
                value={p1}
                onChange={(e) => setP1(e.target.value)}
                onBlur={(e) => textQuerySet(e, "p1", paramP1)}
                onKeyDown={(e) => handleKeyPress(e)}
                label="Player 1"
                variant="standard"
                sx={{ mr: 4, ml: 0, mb: 1 }}
              />
              <Avatar sx={{ mb: 1 }}>VS</Avatar>
              <TextField
                value={p2}
                onChange={(e) => setP2(e.target.value)}
                onBlur={(e) => textQuerySet(e, "p2", paramP2)}
                onKeyDown={(e) => handleKeyPress(e)}
                label="Player 2"
                variant="standard"
                sx={{ mr: 0, ml: 4, mb: 1 }}
              />
              <CharacterSelect
                game={paramGame}
                char={paramC2}
                onCharChange={(e) => charChange(e, "c2", paramC2)}
                size="large"
              />
              {(twoChars || threeChars) && (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{ mr: 0, width: 50 }}
                  >
                    <CharacterSelect
                      game={paramGame}
                      char={paramP2C2}
                      onCharChange={(e) => charChange(e, "p2c2", paramP2C2)}
                      size="medium"
                    />
                  </Box>
                </>
              )}
              {threeChars && (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{ mr: 0, width: 50 }}
                  >
                    <CharacterSelect
                      game={paramGame}
                      char={paramP2C3}
                      onCharChange={(e) => charChange(e, "p2c3", paramP2C3)}
                      size="small"
                    />
                  </Box>
                </>
              )}
              <Container display="flex" sx={{ textAlign: "center", mt: 3 }}>
                <TextField
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                  onBlur={(e) => textQuerySet(e, "tag", paramTag)}
                  onKeyDown={(e) => handleKeyPress(e)}
                  size="small"
                  label="Tag"
                  variant="outlined"
                />
                {!fixedOrder && (twoChars || threeChars) && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={paramOrder === "true"}
                        onChange={orderChange}
                        color="primary"
                      />
                    }
                    label="Match Team Order"
                    sx={{ ml: 4 }}
                  />
                )}
              </Container>
            </Grid>
          )}
        </Container>
      ) : (
        <Stack
          component={Container}
          display="flex"
          spacing={2}
          flexGrow={0}
          sx={{
            paddingTop: 4,
            paddingBottom: 2,
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <GameSelect game={paramGame} onGameChange={gameChange} />
          {paramGame && (
            <div>
              <Box display="flex" alignItems="end" flexGrow={1}>
                {threeChars && (
                  <>
                    <Box
                      display="flex"
                      justifyContent="end"
                      sx={{ mr: 0, width: 50 }}
                    >
                      <CharacterSelect
                        game={paramGame}
                        char={paramP1C3}
                        onCharChange={(e) => charChange(e, "p1c3", paramP1C3)}
                        size="small"
                      />
                    </Box>
                  </>
                )}
                {(threeChars || twoChars) && (
                  <>
                    <Box
                      display="flex"
                      justifyContent="end"
                      sx={{ mr: 0, width: 50 }}
                    >
                      <CharacterSelect
                        game={paramGame}
                        char={paramP1C2}
                        onCharChange={(e) => charChange(e, "p1c2", paramP1C2)}
                        size="medium"
                      />
                    </Box>
                  </>
                )}
                <Box
                  display="flex"
                  justifyContent="end"
                  sx={{ mr: 0, width: 60 }}
                >
                  <CharacterSelect
                    game={paramGame}
                    char={paramC1}
                    onCharChange={(e) => charChange(e, "c1", paramC1)}
                    size="large"
                  />
                </Box>
                <TextField
                  value={p1}
                  onChange={(e) => setP1(e.target.value)}
                  onBlur={(e) => textQuerySet(e, "p1", paramP1)}
                  onKeyDown={(e) => handleKeyPress(e)}
                  fullWidth
                  label="Player 1"
                  variant="standard"
                  sx={{ mb: 1 }}
                />
              </Box>
              <Box display="flex" alignItems="end" flexGrow={1} sx={{ mt: 1 }}>
                {threeChars && (
                  <>
                    <Box
                      display="flex"
                      justifyContent="end"
                      sx={{ mr: 0, width: 50 }}
                    >
                      <CharacterSelect
                        game={paramGame}
                        char={paramP2C3}
                        onCharChange={(e) => charChange(e, "p2c3", paramP2C3)}
                        size="small"
                      />
                    </Box>
                  </>
                )}
                {(threeChars || twoChars) && (
                  <>
                    <Box
                      display="flex"
                      justifyContent="end"
                      sx={{ mr: 0, width: 50 }}
                    >
                      <CharacterSelect
                        game={paramGame}
                        char={paramP2C2}
                        onCharChange={(e) => charChange(e, "p2c2", paramP2C2)}
                        size="medium"
                      />
                    </Box>
                  </>
                )}
                <Box
                  display="flex"
                  justifyContent="end"
                  sx={{ mr: 0, width: 60 }}
                >
                  <CharacterSelect
                    game={paramGame}
                    char={paramC2}
                    onCharChange={(e) => charChange(e, "c2", paramC2)}
                    size="large"
                  />
                </Box>
                <TextField
                  value={p2}
                  onChange={(e) => setP2(e.target.value)}
                  onBlur={(e) => textQuerySet(e, "p2", paramP2)}
                  onKeyDown={(e) => handleKeyPress(e)}
                  label="Player 2"
                  fullWidth
                  variant="standard"
                  sx={{ mb: 1 }}
                />
              </Box>
              <Box
                display="flex"
                justifyContent={"center"}
                flexGrow={1}
                sx={{ mt: 2 }}
              >
                <TextField
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                  onBlur={(e) => textQuerySet(e, "tag", paramTag)}
                  onKeyDown={(e) => handleKeyPress(e)}
                  size="small"
                  label="Tag"
                  variant="outlined"
                  fullWidth
                />
              </Box>
              {!fixedOrder && (twoChars || threeChars) && (
                <Box
                  display="flex"
                  justifyContent={"center"}
                  flexGrow={1}
                  sx={{ mt: 2 }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={paramOrder === "true"}
                        onChange={orderChange}
                        color="primary"
                      />
                    }
                    label="Match Team Order"
                  />
                </Box>
              )}
            </div>
          )}
        </Stack>
      )}
    </>
  );
}
