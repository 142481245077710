import {
  Stack,
  Container,
  Card,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import mbaacc from "../assets/game_logos/mbaacc.png";
import mbtl from "../assets/game_logos/mbtl.png";
import maab from "../assets/game_logos/maab.png";

export default function OtherGames() {
  const games = [
    {
      game: "Melty Blood Actress Again Current Code",
      dbName: "mbaacc.melty.games",
      url: "http://mbaacc.melty.games",
      logo: mbaacc,
    },
    {
      game: "Melty Blood: Type Lumina",
      dbName: "lumina.melty.games",
      url: "http://lumina.melty.games",
      logo: mbtl,
    },
    {
      game: "Million Arthur: Arcana Blood",
      dbName: "enchant-boost.net",
      url: "https://www.enchant-boost.net/",
      logo: maab,
    },
  ];

  return (
    <div>
      <Stack
        component={Container}
        spacing={0}
        display="flex"
        sx={{ mt: 4, flexGrow: 1, paddingRight: "16px", paddingLeft: "16px" }}
      >
        {games.map((g) => (
          <Card
            key={g.game}
            variant="outlined"
            sx={{
              boxShadow: 1,
              mb: 4,
              backgroundColor: "secondBackground.main",
              alignSelf: "center",
              width: "100%",
              maxWidth: 400,
            }}
          >
            <Stack
              component={Box}
              display="flex"
              spacing={0}
              sx={{
                flexGrow: 1,
                textAlign: "center",
                alignContent: "center",
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Box
                display="flex"
                sx={{ mt: 1, flexGrow: 1, justifyContent: "center" }}
              >
                <Typography variant="h6" fontSize={22} sx={{ display: "flex" }}>
                  {g.game}
                </Typography>
              </Box>
              <Divider sx={{ mt: 1, mb: 1 }} />
              <Box
                display="flex"
                sx={{
                  justifyContent: "center",
                  height: 102,
                  alignContent: "center",
                }}
              >
                <img
                  src={g.logo}
                  alt={g.game}
                  style={{
                    alignSelf: "center",
                    maxWidth: 400,
                    maxHeight: 120,
                    height: "auto",
                    display: "block",
                    imageRendering: "-webkit-optimize-contrast",
                  }}
                />
              </Box>
              <Divider sx={{ mt: 1, mb: 1 }} />
              <a
                href={g.url}
                target="_blank"
                rel="noreferrer"
                style={{
                  alignSelf: "center",
                  textDecorationColor: "white",
                  color: "white",
                }}
              >
                <Typography fontSize={15} sx={{ alignSelf: "center", mb: 1 }}>
                  {g.dbName}
                </Typography>
              </a>
            </Stack>
          </Card>
        ))}
      </Stack>
    </div>
  );
}
