import { Link } from "react-router-dom";
import { useState } from "react";
import {
  Button,
  Box,
  AppBar,
  Typography,
  IconButton,
  Toolbar,
  Drawer,
  Divider,
  Stack,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/logo.png";

export default function NavBarMobile() {
  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    setDrawer(open);
  };

  return (
    <nav>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" elevation={1} enableColorOnDark>
          <Toolbar sx={{ height: 15 }}>
            <Link to="/">
              <Box
                component="img"
                src={logo}
                alt="Home"
                sx={{ width: 70, mt: "6px" }}
              />
            </Link>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Typography
                color="textPrimary"
                fontSize={18}
                fontWeight={100}
                sx={{ ml: 1 }}
              >
                Replay Theater
              </Typography>
            </Link>
            <Box display="flex" flexGrow={1} />
            <IconButton
              size="large"
              anchor="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawer}
              onBackdropClick={toggleDrawer(false)}
            >
              <Stack
                display="flex"
                spacing={1}
                sx={{
                  width: 180,
                  paddingTop: 1,
                  paddingBottom: 2,
                  textAlign: "center",
                }}
              >
                <IconButton
                  size="small"
                  aria-label="menu"
                  onClick={toggleDrawer(false)}
                  sx={{ alignSelf: "start", ml: 1 }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
                <Divider />
                <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                  <Button
                    variant="text"
                    fullWidth
                    onClick={toggleDrawer(false)}
                    color="inherit"
                    sx={{ height: 50 }}
                  >
                    {" "}
                    Home{" "}
                  </Button>
                </Link>
                <Link
                  to="/othergames"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <Button
                    variant="text"
                    fullWidth
                    onClick={toggleDrawer(false)}
                    color="inherit"
                    sx={{ height: 50 }}
                  >
                    {" "}
                    Other Games{" "}
                  </Button>
                </Link>
                <Link
                  to="/addmatches"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <Button
                    variant="text"
                    fullWidth
                    onClick={toggleDrawer(false)}
                    color="inherit"
                    sx={{ height: 50 }}
                  >
                    {" "}
                    Add Matches +{" "}
                  </Button>
                </Link>
              </Stack>
            </Drawer>
          </Toolbar>
        </AppBar>
      </Box>
    </nav>
  );
}
